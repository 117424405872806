<template lang="pug">
include ../../../../configs/template
div.row
  div.col-sm-12.col-md-6.d-flex.align-end
    +field-validation('body.additional_info.other_number', 'number', '["required", "numberAndSymbol"]')(:readonly="isCheckDocument")
  div.col-sm-12.col-md-6.d-flex.align-end
    +select-validation('body.additional_info.branch_office', 'ListBranchOffice', 'affiliate', 'namelang', '["required"]')
  div.col-sm-12.col-md-6.d-flex.align-end
    +date-picker-validation('body.additional_info.date_issued', 'dateIssue', 'dateObjectIssued', '["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(
      :readonly="isCheckDocument"
      max="2021-01-01"
    )
  div(v-if="isCheckDocument").col-sm-12.col-md-6.d-flex.align-end
    +field-validation('body.additional_info.blank_strict_report', 'strictBlank', '["required", "numeric"]')
  h5(v-if="isCheckDocument").text-left.col-12 {{$t('agent')}} - {{$t('captain')}}:
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +field-validation('last_name_ukr', 'lastNameUK', '["required", "alphaUA"]')
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +field-validation('first_name_ukr', 'firstNameUK', '["required", "alphaUA"]')
  h5(v-if="isCheckDocument").text-left.col-12 {{$t('agent')}} - {{$t('captainEng')}}:
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +field-validation('last_name_eng', 'lastNameEN', '["required", "alphaEN"]')
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +field-validation('first_name_eng', 'firstNameEN', '["required", "alphaEN"]')

</template>
<script>
import { verificationSC } from '@/mixins/validationRules'
import { mapState } from 'vuex'

const initBody = () => (
  {
    additional_info: {
      other_number: null,
      branch_office: null,
      blank_strict_report: null,
      new_record: false,
      date_issued: null
    }
  }
)

export default {
  props: { isCheckDocument: { type: Boolean, default: false } },
  data () {
    return {
      last_name_eng: null,
      first_name_eng: null,
      body: initBody(),
      isLoading: false,
      last_name_ukr: null,
      first_name_ukr: null
    }
  },
  validations () { return verificationSC.sailorRecordBook(this) },
  computed: {
    ...mapState({
      ListBranchOffice: state => state.directory.affiliate,
      namelang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    dateObjectIssued () {
      return this.body.additional_info.date_issued ? new Date(this.body.additional_info.date_issued) : null
    }
  }

}
</script>
